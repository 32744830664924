import NuevoLeon from '../assets/imgs/nl2.svg'
import Economia from '../assets/imgs/economia_200px.png'
import '../assets/css/navbar.css'

export const Header = () => {
  return (
    <nav>
    <div className="nav-links">
        <ul className="logos">

            <li><a href="https://www.nl.gob.mx/"> <img className='nl_logo' src={NuevoLeon} alt="Estado de Nuevo Leon" /></a>  </li>
            <li> <h2>PLATAFORMA DE REGISTRO IMPULSO NUEVO LEÓN</h2></li>		
            {/* <li><a href="https://www.nl.gob.mx/economia"><img src={Economia} alt="Economia Nuevo Leon- Gabinete de generacion de riqueza sostenible" /> </a>  </li> */}
        </ul>
    </div>
    
        <hr />
    </nav>
  )
}
