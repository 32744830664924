import React, { useEffect, useState } from 'react'
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { IoIosArrowDropupCircle } from "react-icons/io";

import { Navbar } from '../components/Navbar'
import '../assets/css/dashboard-admin.css'
import cedulasApi from '../api/cedulasApi'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import adminApi from '../api/adminApi'
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import moment from 'moment';
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import getDay from "date-fns/getDay"
import dateApi from '../api/dateApi'

export const HomeAdmin = () => {
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };
  const { validateSession, user } = useAuth()
  validateSession()
  const [pending, setPending] = useState(false)
  const [search, setSearch] = useState('')
  const [mtyData, setMtyData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [links, setLinks] = useState([])
  const [addLink, setAddLinks] = useState(false)
  const [msgSearch, setMsgSearch] = useState('')
  const navigate = useNavigate()

  const searchCedula = async (e) => {
    e.preventDefault()
    if (!search) {
      setMsgSearch("Ingrese un RFC o Cédula Valida")
      return
    }
    try {
      const { data } = await cedulasApi.get('/' + search)
      console.log(data)
      if (data && !data.error) {
        console.log(data)
        if (user.email.toUpperCase().includes('.MONTERREY@') && data.completed === 1) {
          setMsgSearch("Solicitud previamente validada")
          return
        }
        setMsgSearch('')
        navigate('/admin/' + data.cedula, { state: data })
      } else {
        setMsgSearch(data.msg || "Error al buscar la solicitud")
      }
    } catch (error) {

    }
  }
  const getCedulas = async () => {
    const { data } = await adminApi.get('/cedulas/');
    if (data && !data.error) {
      const filterData = data.cedulas.filter(d => [2354, 2355, 2356, 2357].includes(d.id_user))
      console.log({ filterData })
      setMtyData(filterData)
      setFilterData(filterData)
    }
  }

  const getLinks = async () => {
    try {
      const { data } = await dateApi.get('/links');
      if (data && !data.error) {
        console.log({ data })
        setLinks(data.links);
      }
    } catch (error) {
      console.error('Error al obtener enlaces:', error);
      // Aquí podrías mostrar un mensaje de error al usuario o tomar otras medidas según sea necesario
    }
  };
  useEffect(() => {
    if (user.email.toUpperCase().includes('.MONTERREY@')) {
      getCedulas()
    }
    getLinks()
  }, [])


  const INITIAL_STATE_ADMIN = { name: "", email: "", rol: "1" }
  const [newAdmin, setNewAdmin] = useState(INITIAL_STATE_ADMIN)
  const [msgNewAdmin, setMsgNewAdmin] = useState("")
  const handleNewAdmin = async (e) => {
    e.preventDefault()
    if (!newAdmin.name) {
      setMsgNewAdmin("El nombre es obligatorio")
      return
    } else if (!newAdmin.email) {
      setMsgNewAdmin("El correo es obligatorio")
      return
    }
    try {
      const password = '1MPUILS02024';
      const { data } = await adminApi.post('/sign-up', { ...newAdmin, password });
      if (data && !data.error) {
        Swal.fire({
          title: "Listo",
          text: data.text || "Usuario creado correctamente",
          icon: 'success',
          timer: 2000,
        })
        setNewAdmin(INITIAL_STATE_ADMIN)
      } else {
        Swal.fire({
          title: data.title || "Error",
          text: data.text || "Error al crear el usuario",
          icon: data.type || 'error',
          timer: 2000,
        })

      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Erro al crear usuario, intente más tarde o pongase en contacto con el administrador",
        icon: 'error',
        timer: 2000,
      })
    }

  }
  const [blockSelection, setBlockSelection] = useState("all")
  const [selectedDate, setSelectedDate] = useState(setHours(setMinutes(new Date(), 11), 11));
  const LUNCH_TIME = ["13:00", '13:30']
  const [blockedHours, setBlockedHours] = useState(LUNCH_TIME);
  const [blockedDates, setBlockedDates] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChangeRangeDay = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const blockDates = async () => {
    if (blockSelection === 'multi-day') {
      console.log(moment(startDate).format('DD/MM/YYYY'))
      console.log(moment(endDate).format('DD/MM/YYYY'))
      const result = [];
      let currentDate = startDate;

      while (currentDate <= endDate) {
        result.push(currentDate.toLocaleDateString());
        currentDate.setDate(currentDate.getDate() + 1);
      }
      try {
        const { data } = await dateApi.post('/disable/dates', { date: result });
        if (data && !data.error) {
          Swal.fire({
            title: "Listo",
            text: data.text || "Usuario creado correctamente",
            icon: 'success',
            timer: 2000,
          })
          setNewAdmin(INITIAL_STATE_ADMIN)
        } else {
          Swal.fire({
            title: data.title || "Error",
            text: data.text || "Error al crear el usuario",
            icon: data.type || 'error',
            timer: 2000,
          })

        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Erro al crear usuario, intente más tarde o pongase en contacto con el administrador",
          icon: 'error',
          timer: 2000,
        })
      }
    } else if (blockSelection === 'all') {
      console.log(selectedDate)
      try {
        const { data } = await dateApi.post('/disable/dates', { date: moment(selectedDate).format('DD/MM/YYYY') });
        if (data && !data.error) {
          Swal.fire({
            title: "Listo",
            text: data.text || "Usuario creado correctamente",
            icon: 'success',
            timer: 2000,
          })
          setNewAdmin(INITIAL_STATE_ADMIN)
        } else {
          Swal.fire({
            title: data.title || "Error",
            text: data.text || "Error al crear el usuario",
            icon: data.type || 'error',
            timer: 2000,
          })

        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Erro al crear usuario, intente más tarde o pongase en contacto con el administrador",
          icon: 'error',
          timer: 2000,
        })
      }
    }
  }

  const [searchDate, setSearchDate] = useState("")
  const [msgSearchDate, setMsgSearchDate] = useState("")

  const handleSearchDate = async (e) => {
    e.preventDefault()
    if (!searchDate) {
      setMsgSearchDate("Ingrese un RFC o Cédula Valida")
      return
    }
    try {
      const { data } = await cedulasApi.get('/' + searchDate)
      if (data && !data.error) {
        console.log(data)
        setMsgSearch('')
        navigate('/cita/reagendar/' + data.cedula, { state: data })
      } else {
        setMsgSearchDate(data.msg || "Error al buscar la solicitud")
      }
    } catch (error) {

    }
  }

  const handleSwitchData = () => {
    setPending(!pending)
    if (!pending) {
      const data = mtyData.filter(d => !d.approved)
      setFilterData(data)
    } else {
      setFilterData(mtyData)
    }
  }
  const createLink = (link) => {
    dateApi.post('/links', link)
      .then(response => setLinks([...links, response.data.link]))
      .catch(error => console.error('There was an error creating the link!', error));
  };

  const updateLink = (id, updatedLink) => {
    dateApi.put(`/links/${id}`, updatedLink)
      .then(response => setLinks(links.map(link => (link.id === id ? response.data.link : link))))
      .catch(error => console.error('There was an error updating the link!', error));
  };

  const deleteLink = (id) => {
    dateApi.delete(`/links/${id}`)
      .then(() => setLinks(links.filter(link => link.id_link !== id)))
      .catch(error => console.error('There was an error deleting the link!', error));
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col ">
        <div className="dashboard-name">
          <h3 className='text-lg font-extrabold'>PANEL DE ADMINISTRACIÓN</h3>
        </div>
        <div className="dashboard-cards">
          <div className="card-container rounded-md bg-transparent">
            <div className="card-container-txt">
              <h2 className="text-md font-semibold">
                Buscar registro
              </h2>
              <p>Ingresa numero de cedula o RFC</p>
            </div>
            <div className="search-date-form">
              <form className="form-field search-date">
                <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" id="cedula" name="cedula" />
                <button onClick={searchCedula} className="btn btn-primary"> <i className="fas fa-search"></i> Buscar</button>
              </form>
              <span>{msgSearch}</span>
            </div>
          </div>
          {
            user.email.toUpperCase().includes('.MONTERREY@') &&
            <div className="dashboard-cards completed-cards">
              <div className="pendingSwitch">
                <button disabled={!pending} onClick={handleSwitchData} className={!pending ? "complete active" : "complete"} >Evaluadas</button>
                <button disabled={pending} onClick={handleSwitchData} className={pending ? "pending active" : "pending"}>Sin Evaluar</button>
              </div>
              {
                mtyData.length ?
                  filterData.filter((d) => !pending && d.approved === 0 || d.approved === 1 ? d : d).map(date => (
                    <div key={date.cedula} className="date-card">
                      <div className="process">
                        <div className={`process-form step ${date.id_form ? 'completed' : ""}`}>
                          <i className="fa-regular fa-file-lines"></i>
                          <span>Cuestionario</span>
                        </div>
                        <div className={`process-file step ${date.id_file ? 'completed' : ""}`}>
                          <i className="fa-regular fa-folder-open"></i>
                          <span>Documentos</span>
                        </div>
                        <div className={`process-date step ${(date.id_date || date.no_date == 1) ? 'completed' : ""}`}>
                          <i className="fa-regular fa-calendar-check"></i>
                          {
                            date.no_date == 1 ?
                              <span>Sin Cita</span>
                              :
                              <span>Cita</span>
                          }
                        </div>
                        <div className={`process-date step ${date.completed && date.approved === 1 ? 'completed' : date.completed && date.approved === 0 ? 'declinada' : ""}`}>
                          <i className={`fa-solid ${!date.completed ? 'fa-circle-question' : date.completed && date.approved === 1 ? 'fa-circle-check' : 'fa-circle-xmark'} `}></i>
                          {
                            (date.approved === 0 || date.approved === 1) ?
                              <>
                                <span className="status"> {date.approved === 1 ? "Aprobada" : "Declinada"} </span>
                              </>
                              :
                              <span className="status"> Sin evaluar </span>
                          }
                        </div>
                      </div>
                      <p className="cedula">{date.cedula}</p>
                      <div className="date-card-text">
                        <h3>{date['form.NOMBRE_EMPRESA']}</h3>
                      </div>
                    </div>
                  ))
                  :
                  <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <h3>Aún no hay solicitudes</h3>
                    <p>Aquí se mostrarán sus solicitudes y el estado en que se encuentran. </p>
                    <Link to="formulario" className="btn btn-primary">Generar</Link>
                  </div>
              }
            </div>

          }
          {
            user.rol !== '1' &&
            <div className='bg-gray-200 w-full flex flex-col'>
              <h3 className='p-2 text-lg font-extrabold text-gray-400'>FUNCIONES DE SUPERUSUARIO</h3>
              <div className="flex flex-wrap">
                <div className="card-container mb-3 w-1/2" >
                  <div className="card-container-txt">
                    <h2 className="text-md font-semibold">
                      Reagendar cita
                    </h2>
                  </div>
                  <div className="search-date-form">
                    <form className="form-field search-date">
                      <input value={searchDate} onChange={(e) => setSearchDate(e.target.value)} type="text" id="cedula" name="cedula" />
                      <button onClick={handleSearchDate} className="btn btn-primary"> <i className="fas fa-search"></i> Buscar</button>
                    </form>
                    <span>{msgSearchDate}</span>
                  </div>
                </div>
                <div className="card-container mb-3 w-1/2">
                  <div className="card-container-txt">
                    <h2 className="text-md font-semibold">
                      Agregar Administrador
                    </h2>
                    <p>Se asignará una contraseña provisional</p>
                  </div>
                  <div className="search-date-form">
                    <form className="sign-up-form" onSubmit={handleNewAdmin}>
                      <span>{msgNewAdmin}</span>
                      <div className="form-field">
                        <label htmlFor="sign-up-name">Nombre</label>
                        <input value={newAdmin.name} onChange={(e) => setNewAdmin({ ...newAdmin, name: e.target.value })} type="text" name="name" id="sign-up-name" required />
                      </div>
                      <div className="form-field">
                        <label htmlFor="sign-up-email">Correo</label>
                        <input value={newAdmin.email} onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })} type="email" name="email" id="sign-up-email" required />
                      </div>
                      <div className="form-field">
                        <label htmlFor="sign-up-rol">Rol</label>
                        <select value={newAdmin.rol} onChange={(e) => setNewAdmin({ ...newAdmin, rol: e.target.value })} name="rol" id="sign-up-rol">
                          <option value="1">Administrativo</option>
                          <option value="2">Super Usuario</option>
                        </select>
                      </div>

                      <button className="btn btn-primary" type="submit">Registrar</button>
                    </form>
                  </div>
                </div>
                <div className="card-container mb-3 w-1/2">
                  <div className="card-container-txt">
                    <h2 className="text-md font-semibold">
                      Bloquear dias de servicio
                    </h2>
                    <p>Sabados y domingos ya se encuetran deshabilitados</p>
                  </div>
                  <div className="inputfield selection-radio">
                    <select value={blockSelection} onChange={(e) => setBlockSelection(e.target.value)}>
                      <option value="all">Un dia</option>
                      <option value="multi-day">Varios dias</option>
                      {/* <option value="multi-time">Entre ciertas horas</option>
                      <option value="select">Una hora en específico</option> */}
                    </select>
                  </div>

                  <div className="inputfield">
                    <div className="form-field">
                      <label htmlFor="datepicker"><i className="far fa-calendar"></i> Selecciona una fecha en el calendario</label>
                      <DatePicker
                        onChange={blockSelection === 'multi-day' ? onChangeRangeDay : (date) => setSelectedDate(date)}
                        startDate={startDate}
                        endDate={blockSelection === 'multi-day' ? endDate : null}
                        selectsRange={blockSelection === 'multi-day' ? true : false}
                        inline
                        selected={blockSelection === 'multi-day' ? startDate : selectedDate}
                        showTimeSelect={blockSelection === 'all' || blockSelection === 'multi-day' ? false : true}
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        filterDate={isWeekday}
                        dateFormat="dd/MM/yyyy h:mm"
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 30), 16)}
                        timeCaption="Time"
                        excludeDates={blockedDates.map(date =>
                          moment(date, 'DD/MM/YYYY').toDate())
                        }
                        excludeTimes={blockedHours.map((hour) =>
                          moment(selectedDate)
                            .startOf('day')
                            .add(hour, 'hours')
                            .toDate()
                        )}
                      />
                    </div>
                  </div>

                  <button className="btn btn-primary" onClick={blockDates}>Deshabilitar</button>


                </div>
                <div className="card-container mb-3 w-1/2 ">
                  <div className="card-container-txt">
                    <h2 className="text-md font-semibold">
                      Links de citas
                    </h2>
                  </div>
                  <div className="inputfield">
                        <p>Lista de links</p>
                        <LinkList links={links} deleteLink={deleteLink} updateLink={updateLink} />
                        {
                          addLink &&
                          <LinkForm createLink={createLink} />
                        }
                        <button onClick={()=>setAddLinks(!addLink)}>{addLink ? <IoIosArrowDropupCircle/> : <FaPlusCircle/>}</button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

      </div>
    </>
  )
}
const LinkList = ({ links, deleteLink, updateLink }) => {
  const [edit, setEdit] = useState({ id_link: null, value: { link: '', user: '' } });

  const handleUpdate = (id_link, newValue) => {
    updateLink(id_link, newValue);
    setEdit({ id_link: null, value: { link: '', user: '' } });
  };

  return (
    <div className='flex flex-col gap-3'>
      {links.map((link) => (
        <div key={link.id_link} className='border-b-2 border-gray-300 w-full'>
          {edit.id_link === link.id_link ? (
            <LinkForm
              edit={edit}
              onSubmit={(value) => handleUpdate(link.id_link, value)}
            />
          ) : (
            <div className='flex justify-between'>
              <span>{link.link}</span> - <span>{link.user}</span>
              <div className="flex gap-2">
                <button className='  p-1 text-white' onClick={() => deleteLink(link.id_link)}><FaTrash color='red'/></button>
                <button onClick={() => setEdit({ id_link: link.id_link, value: link })}>
                  <FaEdit color='gray' />
                </button>

              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
const LinkForm = ({ createLink, edit, onSubmit }) => {
  const [input, setInput] = useState({ link: '', user: '' });

  useEffect(() => {
    if (edit) {
      setInput(edit.value);
    }
  }, [edit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!edit) {
      createLink(input);
    } else {
      onSubmit(input);
    }
    setInput({ link: '', user: '' });
  };

  return (
    <form onSubmit={handleSubmit} className='flex justify-between'>
      <input
        type="text"
        placeholder="link"
        name="link"
        value={input.link}
        onChange={handleChange}
        required
      />
      <input
        className=''
        type="text"
        placeholder="Usuario"
        name="user"
        value={input.user}
        onChange={handleChange}
        required
      />
      <button  type="submit">{edit ? 'Actualizar' : 'Agregar'}</button>
    </form>
  );
};