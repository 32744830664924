import React, { useState } from 'react'
import { Navbar } from '../components/Navbar'
import { useAuth } from '../hooks/useAuth'
import '../assets/css/reportes.css'
import adminApi from '../api/adminApi'
import moment from 'moment';
import Swal from 'sweetalert2'
import Montemorelos from '../assets/imgs/Montemorelos_admin.png'
import Mty from '../assets/imgs/mty_escudo.png'


export const Reportes = () => {
  const { user } = useAuth()
  const [evaluator, setEvaluator] = useState("poncho")
  const [date, setDate] = useState(null)
  const handleDownload = async (e) => {
    const selection = e.target.id;
    if (selection === 'all') {
      try {
        const { data } = await adminApi.get('/cedulas')
        if (data) {
          getCSV(data.cedulas, 'layout')
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'approved') {
      try {
        const { data } = await adminApi.get('/cedulas')
        if (data && data.cedulas) {
          const filterData = data.cedulas.filter(d=>d.approved =='1')
          getCSV(filterData, 'layout')
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'rejected') {
      try {
        const { data } = await adminApi.get('/cedulas')
        if (data && data.cedulas) {
          const filterData = data.cedulas.filter(d=>d.approved =='0')
          getCSV(filterData, 'layout')
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'completed') {
      try {
        const { data } = await adminApi.get('/cedulas')
        if (data && data.cedulas) {
          const filterData = data.cedulas.filter(d=>d.approved =='0' || d.approved =='1')
          getCSV(filterData, 'layout')
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'montemorelos') {
      try {
        const { data } = await adminApi.get('/cedulas')
        console.log(data)
        if (data && data.cedulas) {
          const filterData = data.cedulas.filter(d=> [653, 652, 659, 660, 729].includes(d.id_user))
          //[653, 652, 659, 660, 729]
          getCSV(filterData, 'Montemorelos')
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'monterrey') {
      try {
        const { data } = await adminApi.get('/cedulas')
        if (data && data.cedulas) {
          const filterData = data.cedulas.filter(d=> [2354, 2355, 2356, 2357].includes(d.id_user))
          //[653, 652, 659, 660, 729]
          if (filterData.length === 0) {
            Swal.fire({
              title: "No hay solicitudes",
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000,
            })
            return
          }
          getCSV(filterData, 'Monterrey')
        }
      } catch (error) {
        console.log(error)
      }
    }
    else if (selection === 'poncho') {
      try {
        const f = moment(new Date()).format('DD/MM/YYYY')
        const { data } = await adminApi.post('/cedulas/dates',{date:f})
        if (data && data.cedulas) {
          console.log(data)
          const filterData = data.cedulas.filter(d=>d['date.id_link'] =='1')
          if (filterData.length === 0) {
            Swal.fire({
              title: "No hay citas",
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000,
            })
            return
          }
          getDatesByDay(filterData, 'Citas-Alfonso-' +f)
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'jair') {
      try {
        const f = moment(new Date()).format('DD/MM/YYYY')
        const { data } = await adminApi.post('/cedulas/dates',{date:f})
        if (data && data.cedulas) {
          console.log(data)
          const filterData = data.cedulas.filter(d=>d['date.id_link'] =='2')
          if (filterData.length === 0) {
            Swal.fire({
              title: "No hay citas",
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000,
            })
            return
          }
          getDatesByDay(filterData, 'Citas-Jair-' +f)
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'day') {
      try {
      
        const { data } = await adminApi.post('/cedulas/dates',{date:moment(date).format('L')})
        if (data && data.cedulas) {
          if (evaluator === 'poncho') {
            const filterData = data.cedulas.filter(d=>d['date.id_link'] =='2')
            if (filterData.length === 0) {
              Swal.fire({
                title: "No hay citas",
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
              })
              return
            }
            getDatesByDay(filterData, 'Citas-Alfonso-' +date)
          }else{
            const filterData = data.cedulas.filter(d=>d['date.id_link'] =='1')
            if (filterData.length === 0) {
              Swal.fire({
                title: "No hay citas",
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
              })
              return
            }
            getDatesByDay(filterData, 'Citas-Jair-' +date)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'users') {
      try {
        const { data } = await adminApi.get('/cedulas/users')
        if (data && data.users) {
          if (data.users.length === 0) {
            Swal.fire({
              title: "No hay usuarios",
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000,
            })
            return
          }
          getUserCSV(data.users, 'Usuarios' )
        }
      } catch (error) {
        console.log(error)
      }
    }else if (selection === 'usersCedulas') {
      try {
        const { data } = await adminApi.get('/cedulas/cedulasusers')
        if (data && data.data) {
          
          getCedulasUserCSV(data.data, 'Usuarios' )
        }
      } catch (error) {
        console.log(error)
      }
    }
  
    console.log(selection)
  }

  function getCedulasUserCSV(obj, name="usuarios" ) {
    let data= [];
    obj.forEach(user=> {
        let dataOrdened = {}
        dataOrdened['CEDULA'] = user['cedula']
        dataOrdened['USUARIO'] =user['usuario']? user['usuario'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
        dataOrdened['CORREO'] =user['email']? user['email'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "") : ""
        dataOrdened['EVALUADOR'] =user['evaluador'] ? user['evaluador'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "") : "Sin evaluar";
        dataOrdened['FECHA DE EVALUACION'] =user['evaluation_date']? user['evaluation_date'].substring(0, user['evaluation_date'].length - 5).replaceAll("T"," ") : "Sin evaluar";
        data.push(dataOrdened)
    })
    const headers = {};
    for (const key in data[0]) {
        headers[key] = key
    }
    exportCSVFile(headers, data, name);
}

  function getUserCSV(obj, name="usuarios" ) { 
    obj.forEach(user=> {
        user['rol'] = user['rol'] == 2 ? 'SUPER USUARIO' : user['rol'] == 1 ? 'ADMINISTRATIVO' : 'USUARIO';
        user['email'] =user['email'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "")
        user['name'] =user['name'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "")
    })
    const headers = {};
    for (const key in obj[0]) {
        headers[key] = key
    }
    //console.log(obj, headers);
    exportCSVFile(headers, obj, name);
}

  function getDatesByDay(obj, name="citas del dia" ) {
    let data= [];
    obj.forEach(cedula=> {
        let dataOrdened = {}
        dataOrdened['CEDULA'] = cedula['cedula']
        dataOrdened['DIA'] =cedula['date.date'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "")
        dataOrdened['HORA'] =cedula['date.time'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "")
        dataOrdened['NOMBRE'] =cedula['form.NOMBRE_EMPRESA'] ? cedula['form.NOMBRE_EMPRESA'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "") : cedula['form.NOMBRE_EMPRESA'].replaceAll(",","").trim().replace(/(\r\n|\n|\r)/gm, "");
        dataOrdened['MODALIDAD'] =cedula['date.mode'] == "in-person" ? "Presencial" : "Virtual";
        data.push(dataOrdened)
    })

    //console.log(data)

    const headers = {};

    for (const key in data[0]) {
        headers[key] = key
    }
    
    //console.log(data, headers);
    exportCSVFile(headers, data, name);
}

  function getCSV(obj, name = "cedulas") {

    let data = []
    obj.forEach((cedula, i) => {
      //console.log(cedula["cedula"]);
      let dataOrdened = {}
      dataOrdened["FOLIO DE CÉDULA"] = cedula["cedula"]
      dataOrdened["FECHA DE CAPTURA DE CEDULA"] = cedula['form.createdAt'] ? moment(cedula['form.createdAt'].replaceAll(",", "")).format('L')  : '';
      dataOrdened["FECHA DE VALIDACIÓN DE CÉDULA"] = cedula["evaluation_date"] ? moment( cedula["evaluation_date"].replaceAll(",", "")).format('L') : "";
      dataOrdened["NOMBRE O RAZÓN SOCIAL"] = cedula['form.NOMBRE_EMPRESA'] ? cedula['form.NOMBRE_EMPRESA'].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["NOMBRE DEL REPRESENTANTE LEGAL"] = cedula['form.REPRESENTANTE_LEGAL'] ? cedula['form.REPRESENTANTE_LEGAL'].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["GENERO DEL SOLICITANTE O REPRESENTANTE LEGAL"] = cedula['form.GÉNERO_PERSONA'] ? cedula['form.GÉNERO_PERSONA'].replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["RFC"] = cedula["form.RFC"] ?  `${cedula["form.RFC"].replace(/(\r\n|\n|\r)/gm, "").slice(0, -3)}-${cedula["form.RFC"].replace(/(\r\n|\n|\r)/gm, "").slice(-3)}` : "";
      dataOrdened["REGIMEN FISCAL"] = cedula["form.RÉGIMEN"] ? cedula["form.RÉGIMEN"].replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["CALLE"] = cedula["form.CALLE"] ? cedula["form.CALLE"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["NÚMERO"] = cedula["form.NÚMERO"] ? cedula["form.NÚMERO"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["COLONIA"] = cedula["form.COLONIA"] ? cedula["form.COLONIA"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["MUNICIPIO"] = cedula["form.MUNICIPIO"] ? cedula["form.MUNICIPIO"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["CÓDIGO POSTAL"] = cedula["form.CÓDIGO_POSTAL"] ? cedula["form.CÓDIGO_POSTAL"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["ESTADO"] = cedula["form.ESTADO"] ? cedula["form.ESTADO"].replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["TELÉFONO DE CONTACTO DEL NEGOCIO"] = cedula["form.TELÉFONO_CONTACTO"] ? cedula["form.TELÉFONO_CONTACTO"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["TELÉFONO DEL SOLICITANTE"] =  cedula["form.TELÉFONO_SOLICITANTE"] ? cedula["form.TELÉFONO_SOLICITANTE"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["CORREO ELECTRÓNICO DEL NEGOCIO"] = cedula["form.CORREO_ELECTRÓNICO"] ? cedula["form.CORREO_ELECTRÓNICO"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["CORREO ELECTRÓNICO DEL SOLICITANTE O DEL APODERADO"] = cedula["form.CORREO_ELECTRÓNICO_SOLICITANTE"] ? cedula["form.CORREO_ELECTRÓNICO_SOLICITANTE"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : '';
      dataOrdened["ACTIVIDAD ECONÓMICA SCIAN"] = cedula["form.ACTIVIDAD_ECONÓMICA"] ? cedula["form.ACTIVIDAD_ECONÓMICA"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["ACTIVIDAD ECONÓMICA"] = cedula["ACTIVIDAD_ECONÓMICA"] ? cedula["ACTIVIDAD_ECONÓMICA"] ? cedula["ACTIVIDAD_ECONÓMICA"].replaceAll(",", "") : "" : "";
      dataOrdened["RAMO"] = cedula["RAMO"] ? cedula["RAMO"].replaceAll(",", "") : "";
      dataOrdened["SUBSECTOR"] = cedula["SUBSECTOR"] ? cedula["SUBSECTOR"].replaceAll(",", "") : "";
      dataOrdened["SECTOR"] = cedula["SECTOR"] ? cedula["SECTOR"].replaceAll(",", "") : "";
      dataOrdened["TOTAL DE EMPLEADOS"] = cedula["form.TOTAL_EMPLEADOS"] ? cedula["form.TOTAL_EMPLEADOS"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["TOTAL DE EMPLEADOS MUJERES"] = cedula["form.EMPLEADOS_MUJERES"] ? cedula["form.EMPLEADOS_MUJERES"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["TOTAL DE EMPLEADOS HOMBRES"] = cedula["form.EMPLEADOS_HOMBRES"] ? cedula["form.EMPLEADOS_HOMBRES"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["VENTAS ANUALES 2022"] = cedula["form.VENTAS_2022"] ? cedula["form.VENTAS_2022"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["% DE EXPORTACIÓN 2022"] = cedula["form.EXPORTACION_2022"] ? cedula["form.EXPORTACION_2022"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "0%";
      dataOrdened["VENTAS ANUALES 2021"] = cedula["form.VENTAS_2021"] ? cedula["form.VENTAS_2021"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["% DE EXPORTACIÓN 2021"] = cedula["form.EXPORTACION_2021"] ? cedula["form.EXPORTACION_2021"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "0%";
      dataOrdened["VENTAS ANUALES 2023"] = cedula["form.VENTAS_2023"] ? cedula["form.VENTAS_2023"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["% DE EXPORTACIÓN 2023"] = cedula["form.EXPORTACION_2023"] ? cedula["form.EXPORTACION_2023"].replaceAll(",", "").trim().replace(/(\r\n|\n|\r)/gm, "") : "0%";
      dataOrdened["MERCADOS DE EXPORTACIÓN"] = cedula["form.MERCADOS_DE_EXPORTACIÓN"] ? cedula["form.MERCADOS_DE_EXPORTACIÓN"].replace(/(\r\n|\n|\r)/gm, "").replaceAll('"', "").replaceAll(',', " ") : "";
      dataOrdened["DESTINO DEL CREDITO"] = cedula["form.DESTINO_CRÉDITO"] ? cedula["form.DESTINO_CRÉDITO"].replace(/(\r\n|\n|\r)/gm, "").replaceAll('"', "") : "";
      dataOrdened["EL LOCAL ES"] = cedula["form.LOCAL"] ? cedula["form.LOCAL"].replace(/(\r\n|\n|\r)/gm, "").replaceAll('"', "") : "";
      dataOrdened["INFORMACION FINANCIERA DE LA EMPRESA"] = cedula["INFORMACION_FINANCIERA_DE_LA_EMPRESA"] ? cedula["INFORMACION_FINANCIERA_DE_LA_EMPRESA"] : "";
      dataOrdened["Breve Descripción de las necesidades financieras de la empresa"] = cedula["form.NECESIDADES_FINANCIERAS"] ? cedula["form.NECESIDADES_FINANCIERAS"].replaceAll(",", "-").trim().replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened["BANCO SOLICITADO"] = cedula["form.BANCO"] ? cedula["form.BANCO"].replace(/(\r\n|\n|\r)/gm, "").replaceAll('"', "") : "";
      dataOrdened["Estatus Cédula"] = cedula.approved != null ? cedula.approved == 1 ? 'CEDULA VALIDADA' : cedula.approved == 0 ? cedula.approved = 'CEDULA CANCELADA' : cedula.approved = 'SIN EVALUAR' : "SIN EVALUAR";
      dataOrdened["MONTO DE CREDITO SOLICITADO"] = cedula["form.MONTO_CRÉDITO"] ? cedula["form.MONTO_CRÉDITO"].replaceAll(",", "").replace(/(\r\n|\n|\r)/gm, "") : "";
      dataOrdened['ORIGEN DEL EXPEDIENTE (BANCO /INCLUSION FINANCIERA)'] = ""
      dataOrdened['OBSERVACIONES'] = ""
      dataOrdened['EXPORTA SI/NO'] = cedula["form.EXPORTACION_2023"] ==="0%"  && cedula["form.EXPORTACION_2021"] ==="0%" && cedula["form.EXPORTACION_2022"] ==="0%"  ? "NO" : "SI"
      data.push(dataOrdened)
    })

    //console.log(data)

    const headers = {};

    for (const key in data[0]) {
      headers[key] = key
    }



    exportCSVFile(headers, data, name);
  }

  function convertToCSV(objArray) {
      const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      for (let i = 0; i < array.length; i++) {
          let line = "";
          for (let index in array[i]) {
          if (line != "") line += ",";
      line += array[i][index];
          }
      str += line + "\r\n";
      }
      return str;
  }
  function exportCSVFile(headers, items, fileName) {
    if (headers) {
      items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportName = fileName + ".csv" || "export.csv";

    var cCode, bArr = [];
    bArr.push(255, 254);
    for (var i = 0; i < csv.length; ++i) {
      cCode = csv.charCodeAt(i);
      bArr.push(cCode & 0xff);
      bArr.push(cCode / 256 >>> 0);
    }


    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    <>
      <Navbar />
      <div className="reports-container container">
        <div className="reports-header">
          <div className="title" style={{ fontSize: "1rem" }}>
            <h1>Reportes y descargas</h1>
          </div>
        </div>
        <div className="reports-info">
          <ul>
            <li><button onClick={handleDownload} id="all" className="btn btn-secundary">Descargar todos los registros (aprobados, declinados y sin evaluar) </button> </li>
            <li><button onClick={handleDownload} id="approved" className="btn btn-secundary">Descargar registros aprobados</button> </li>
            <li><button onClick={handleDownload} id="rejected" className="btn btn-secundary">Descargar registros declinados</button> </li>
            <li><button onClick={handleDownload} id="completed" className="btn btn-secundary">Descargar registros evaluados (aprobados y declinados ) </button> </li>
            <li><button onClick={handleDownload} id="montemorelos" className="btn btn-secundary">Descargar registros Montemorelos <img style={{width:'40px', margin:'0 10px'}} src={Montemorelos} alt="Montemorelos" /> </button> </li>
            <li><button onClick={handleDownload} id="monterrey" className="btn btn-secundary">Descargar registros Monterrey <img style={{width:'40px', margin:'0 10px'}} src={Mty} alt="Monterrey" /> </button> </li>
            <li><button onClick={handleDownload} id="poncho" className="btn btn-secundary">Descargar citas de hoy Alfonso</button> </li>
            <li><button onClick={handleDownload} id="jair" className="btn btn-secundary">Descargar citas de hoy Jair</button> </li>
            <li>
              <select value={evaluator} onChange={(e)=>setEvaluator(e.target.value)} name="" id="">
                <option value="poncho">Alfonso</option>
                <option value="jair">Jair</option>
              </select>
              <input type="date" id="day-input" onChange={(e)=>setDate(e.target.value)} />
              <button onClick={handleDownload} id="day" className="btn btn-primary">Descargar citas</button>
            </li>

          </ul>
          {/* Si es super admin */}
          {
            user.rol === '2' &&
            <ul>
              <li><button onClick={handleDownload} id="users" className="btn btn-secundary">Lista de usuarios</button> </li>
              <li><button onClick={handleDownload} id="usersCedulas" className="btn btn-secundary">Lista de usuarios por cedula</button> </li>


            </ul>

          }




        </div>

      </div>
    </>
  )
}
